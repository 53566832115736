import { createContext, useReducer, useEffect } from "react";
import { loginAction, socialLoginAction , singupAction} from "../api/auth";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isLoading: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, isLoading } = action.payload;
    user && localStorage.setItem("cadena_user", JSON.stringify(user));
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isLoading,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  signup:() => Promise.resolve(),

});

export const login = async (data, dispatch) => {
  try {
    dispatch({
      type: "INITIALIZE",
      payload: {
        isLoading: true,
      },
    });
    const user = await loginAction(data);
    console.log(user,"check response in redux")

    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: true,
        isLoading: false,
        user,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: false,
        isLoading: false,
        user: null,
      },
    });
  }
};

export const signup = async (data, dispatch , callback) => {
  try {
    dispatch({
      type: "INITIALIZE",
      payload: {
        isLoading: true,
      },
    });
    const user = await singupAction(data , callback);
    console.log(user,"check response in redux")

    dispatch({
      type: "INITIALIZE",
      payload: {
        // isAuthenticated: true,
        isLoading: false,
        user,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: false,
        isLoading: false,
        user: null,
      },
    });
  }
};
export const logout = async (dispatch) => {
  localStorage.setItem("cadena_user", null);
  dispatch({
    type: "INITIALIZE",
    payload: {
      isAuthenticated: false,
      user: null,
    },
  });
};

export const socialLogin = async (data, dispatch) => {
  try {
    dispatch({
      type: "INITIALIZE",
      payload: {
        isLoading: true,
      },
    });
    const user = await socialLoginAction(data);
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: true,
        isLoading: false,
        user,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: false,
        isLoading: false,
        user: null,
      },
    });
  }
};

export const microsoftLogin = async (user, dispatch) => {
  dispatch({
    type: "INITIALIZE",
    payload: {
      isAuthenticated: true,
      isLoading: false,
      user,
    },
  });
};

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("cadena_user"));
    user &&
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: true,
          isLoading: false,
          user: user,
        },
      });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        signup,
        logout,
        jwtDispatch: dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
