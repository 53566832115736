import axios from "axios";
import { toast } from "react-toastify";
import { config } from "../config";

export const loginAction = (payload) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api-user-login/`, payload)
      .then((response) => {
        console.log(response,"check response")

        if (response.data.code === 200) {
          const data = response.data.data[0];
          toast.success("Logged In Successfully");
          resolve(data);
        } else {
          toast.error(response?.data.message || "Something went wrong");
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });
  export const singupAction = (payload , callback) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/newuser/`, payload)
        .then((response) => {
          console.log(response,"check response")
          if (response.status === 201) {
          console.log(response,"check response")

            resolve({token:response.data.data.token , ...response.data.data.User_Information});
            toast.success(response?.data?.message);
            callback()

          console.log(response,"check response")
            
            
            
          } else {
          console.log(response,"check response")

            toast.error(response?.data.message || "Something went wrong");
            reject(
              response?.data.error ||
                response?.data.message ||
                new Error("Internal server error.")
            );
          console.log(response,"check response")

          }
        })
        .catch((error) => {
       

          toast.error("Something went wrong");
          reject(
            error?.response?.data.error ||
              error?.response?.data.message ||
              new Error("Internal server error")
          );
        });
    });

  // export const singupAction = (data) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(`${config.apiUrl}/newuser/`, data
         
  //       )
  //       .then((response) => {
  //         if (response.data.code === 201) {
  //           // const data = response.data.data;
  //           // resolve(data);
  //           const data = response.data.data[0];
  //           toast.success("SignUp Successfully");
  //           resolve(data);
  //         }
  //         else {
  //           toast.error(response?.data.message || "Something went wrong");
  //           reject(
  //             response?.data.error ||
  //               response?.data.message ||
  //               new Error("Internal server error.")
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         reject(
  //           error?.response?.data.error ||
  //           error?.response?.data.message ||
  //           new Error("Internal server error")
  //         );
  //       });
  //   });
    
export const socialLoginAction = (payload) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/google/`, payload)
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data[0];
          toast.success("Logged In Successfully");
          resolve(data);
        } else {
          toast.error(response?.data.message || "Something went wrong");
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });
