import React, { useState, useEffect } from "react";
import "../assets/css/discovery.css";
import ReactSpeedometer from "react-d3-speedometer"
import logo from "../assets/img/discoverylogo.png";
import email from '../assets/img/envalope.png'
import chat from '../assets/img/chat.png'
import location from '../assets/img/location.png'
import BDAY from '../assets/img/bday.png'
import gender from '../assets/img/gender.png'
import profile from '../assets/img/profilee.png';
import tick from '../assets/img/Tickss.png';
import closeIcon from '../assets/img/close.png';
import copyIcon from '../assets/img/copy.png';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import userIcon from "../assets/img/icons/profile.png";
import { Link, useParams } from "react-router-dom";
import { GetCompanies } from "../api/common"
import { GetCompanyDetail } from "../api/common"
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";


function CompanyDetail() {
  const { user } = useAuth();
  const [companies, setCompanies] = useState([])
  const [companyDetails, setCompanyDetails] = useState()
  const [prospectDataList, setProspectDataList] = useState([])
  const [selectedCompanyId, setSelectedCompanyId] = useState()
  const [isHovered, setIsHovered] = useState(false);
  const { id } = useParams()
  const navigate = useNavigate()
  let rating = companyDetails?.cadenapay_rating
  let buybeaconRating = companyDetails?.buybeacon_rating
  const [copyState, setCopyState] = useState(false);

  const handleCopy = () => {
    const updatesText = companyDetails?.updates.split(",\r\n").join("\n");
    navigator.clipboard.writeText(updatesText);
    setCopyState(true);

    setTimeout(() => {
      setCopyState(false);
    }, 3000);
  };

  const handleClosePopup = () => {
    setIsHovered(false);
  };
  const scrollToConnections = () => {
    const connectionsSection = document.getElementById("ourConnections");
    console.log(connectionsSection, "connectionsSection");
    if (connectionsSection) {
      connectionsSection.scrollIntoView({ behavior: 'smooth' });

    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const styles = {
    outerCard: {
      minHeight: "700px",
      maxHeight:"fit-content",
      backgroundColor: '#000',
      // minWidth: '550px',
      width: "100%",
      borderRadius: '8px',
    
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      color: 'white',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      backgroundColor: '#9c27b0',
      padding: '10px',
      textAlign: 'center',
    },
    title: {
      fontSize: '1.95rem',
      margin: 0,
    },
    innerCard: {
      backgroundColor: '#0C0B0D',
      padding: '20px',
      borderRadius: '8px',
    },
    profile: {
      textAlign: 'center',
    },
    profileImg: {
      borderRadius: '10%',
      width: '225px',
      height: '225px',
    },
    profileName: {
      margin: '15px 0 5px',
      fontWeight: 'bold',
      color: "#ffff",
    },
    profileDesc: {
      fontSize: '1.4rem',
      color: 'rgb(231 225 225)',

    },
    details: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      fontSize: '0.85rem',
      color: 'rgb(231 225 225)',
      margin: '10px 0',
    },
    updates: {
      backgroundColor: '#1e1b1b',
      padding: '10px',
      borderRadius: '4px',
      margin: '10px 0',
    },
    updatesTitle: {
      fontSize: '1.8rem',
      color: '#f4f1f4',
      marginBottom: '10px',
    },
    updateItem: {
      fontSize: '1.45rem',
      color: '#f4f1f4',
      marginBottom: '5px',
    },

    button: {
      flex: 1,
      border: 'none',
      padding: '10px',
      fontSize: '1rem',
      cursor: 'pointer',
      color: 'white',
      margin: '5px',
      borderRadius: '4px',
    },
    actions: {
      display: 'flex',
      marginTop: '10px',
      gap: "10px",
      position: "relative",
      top: '50%',
      "transform": " translateY(50px)",
    },
    emailButton: {
      flex: 1,
      backgroundColor: '#000000', // Transparent background for the email button
      border: '2px solid #9c27b0', // Purple border
      color: '#9c27b0', // Purple text color
      borderRadius: '10px', // Rounded bottom left corner
      padding: '10px',
      fontSize: '18px',
      gap: "20px",
      fontFamily: "inter",
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    chatButton: {
      flex: 1,
      backgroundColor: '#9c27b0', // Solid purple background for the chat button
      color: 'white', // White text color
      border: 'none',
      borderRadius: '10px', // Rounded bottom right corner
      padding: '10px',
      fontSize: '18px',
      gap: "20px",
      fontFamily: "inter",
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    closeIcon: {
      position: "absolute",
      right: "20px",
      top: "25px",
      width: '16px',
      height: '16px',
      cursor: 'pointer',
    },
    icon: {
      color: '#888',
      fontSize: '1rem',
    },
    popup: {
      position: 'absolute',
      top: '27%',
      left: '-550px',
      minwidth: '600px',
      maxWidth: "max-content",
      backgroundColor: '#000',
      // backgroundColor: '#333',
      color: '#fff',
      borderRadius: '8px',
      // padding: '20px',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
      zIndex: 1,
      opacity: 0,
      visibility: 'hidden',
      transition: 'opacity 0.3s ease, visibility 0.3s ease',
    },
    popupVisible: {
      opacity: 1,
      visibility: 'visible',
    },
    popupHeader: {
      backgroundColor: '#616060', // Grey background for popup header
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      padding: '10px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      borderBottom: '1px solid Transparent', // Purple divider
    },
    popupProfileImg: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
    },

    popupRow: {
      display: 'flex',
      gap: "100px",
      justifyContent: 'space-between',
      marginBottom: '15px',
      borderBottom: '1px solid #9c27b0',
      paddingBottom: '15px',
    },
    popupRowLast: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: "100px",
      marginBottom: '15px',
      // borderBottom: '1px solid #6f42c1', 
      // paddingBottom: '15px',
    },
    label: {
      minWidth: "fit-content",
      fontWeight: 'bold',
      fontSize: '1.0em',
      textAlign: "left",
    },
    value: {

      fontSize: '0.9em',
      color: '#fbeaea',

      textAlign: "left",
    },
  };
  const getAllCompanies = async () => {
    console.log(user?.token, "user");
    if (user?.token) {
      try {
        const response = await GetCompanies(user?.token);
        setCompanies(response);
        console.log(response);
      } catch (error) {
        console.error("Error fetching all companies:", error.message);
      }
    } else {

      console.log("Token is not available");
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, [user?.token, id]);

  const getCompanyDetail = async () => {
    if (user?.token && id) {
      try {
        const response = await GetCompanyDetail(user?.token, id);
        const companyData = response?.companyData;
        const prospectData = response?.prospectData;

        setCompanyDetails(companyData);
        setProspectDataList(prospectData)
        console.log(response, "cd");
      } catch (error) {
        console.error("Error fetching company details:", error.message);
      }
    } else {
      console.log("Token or selectedCompanyId is not available");
    }
  };

  useEffect(() => {

    getCompanyDetail(selectedCompanyId);
  }, [user?.token, selectedCompanyId]);


  return (
    <main id="discovery">
      <section className="position-relative nav-header">
        <nav className="navbar navbar-expand-lg navbar-light position-sticky justify-content-between bg-gradient">
          <div className="containerBox container-fluid">
            <Link className="navbar-brand d-flex">
              <img src={logo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" onClick={() => navigate("/")}>
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => navigate("/dashboard")}>
                    Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => navigate("/prospect")}>
                    Prospect search
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => navigate("/list")}>
                    Lists
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => navigate("/prospect")}>
                    IntelliConnect
                  </a>
                </li>
                <li className="nav-item">
                  <button onClick={() => navigate("/login")} className="btn rounded-pill btn-purple signin-btn">
                    {user && user.full_name
                      ? user.full_name
                      : user && user.first_name
                        ? user.first_name
                        : "Get Started"}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
      <section className="company-detail">
        <div className="containerBox">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-12">
              <div className="card p-3">
                <div className="card-header d-flex justify-content-end">
                  <div class="dropdown">
                    <button
                      class="btn btn-purpal rounded-pill dropdown-toggle px-4"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <ul
                      class="dropdown-menu  dropdown-menu-dark text-white dropdown-menu-start"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="d-flex" style={{ height: "43px" }}>
                        <a class="dropdown-item m-auto" href="#">
                          Cadena Account Manager
                        </a>

                      </li>
                      <hr style={{ marginLeft: "5%", marginRight: "5%", color: "white" }} className="my-0"></hr>
                      <li className="d-flex" style={{ height: "43px" }}>
                        <a class="dropdown-item m-auto" onClick={() => navigate("/intelliConnect")}>
                          IntelliConnect
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="Company-SocialDetail text-white d-flex gap-5 flex-lg-row flex-column justify-content-lg-start justify-content-center">
                    <div className="comapny-logo d-flex">
                      <img className="my-auto" src={companyDetails?.company_logo} alt="company-logo" style={{ maxWidth: "130px" }} />
                    </div>
                    <div className="company-info">
                      <h3>{companyDetails?.company_name}</h3>
                      <Link
                        to="tel:+971 2 7070000"
                        className="text-decoration-none text-white"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M4.31775 1.3004C4.61809 1.60054 4.91946 1.89982 5.21844 2.20132C5.74567 2.7331 5.74669 3.35336 5.22152 3.88103C4.84481 4.25958 4.47031 4.64035 4.08626 5.01121C3.98478 5.10909 3.97675 5.18972 4.02817 5.31305C4.28034 5.91862 4.64492 6.45484 5.05512 6.95911C5.87961 7.97261 6.81208 8.87217 7.9241 9.57016C8.16226 9.71963 8.4257 9.82844 8.67377 9.96305C8.80139 10.0322 8.88886 10.0102 8.99103 9.90463C9.36671 9.51635 9.75145 9.1366 10.1353 8.75618C10.6381 8.25806 11.2692 8.25703 11.7743 8.7572C12.3913 9.36841 13.0057 9.98252 13.6175 10.5989C14.1307 11.1158 14.1266 11.7475 13.6105 12.2681C13.2618 12.62 12.8933 12.954 12.5636 13.3226C12.0837 13.8592 11.4816 14.0329 10.7929 13.995C9.79194 13.94 8.86853 13.6079 7.97929 13.1757C6.00262 12.2152 4.3128 10.884 2.89923 9.20152C1.854 7.95758 0.990216 6.6038 0.422159 5.07475C0.147441 4.33526 -0.0490299 3.57782 0.0107656 2.77666C0.047668 2.28229 0.233376 1.86173 0.597787 1.51495C0.98902 1.14239 1.3618 0.750515 1.74723 0.371628C2.2519 -0.124616 2.88317 -0.123591 3.38904 0.372995C3.70134 0.679453 4.00835 0.991036 4.31775 1.3004Z"
                            fill="#9D13C5"
                          />
                        </svg>{" "}
                        {companyDetails?.company_phone}
                      </Link>
                      <div className="btn-box d-flex gap-3 mt-4 justify-content-lg-start justify-content-center">
                        <button className="btn btn-white rounded-pill px-4 linkedin">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                          >
                            <path
                              d="M13.8896 0.906128H1.10742C0.495117 0.906128 0 1.36548 0 1.9334V14.1298C0 14.6978 0.495117 15.1599 1.10742 15.1599H13.8896C14.502 15.1599 15 14.6978 15 14.1326V1.9334C15 1.36548 14.502 0.906128 13.8896 0.906128ZM4.4502 13.0525H2.22363V6.24851H4.4502V13.0525ZM3.33691 5.32146C2.62207 5.32146 2.04492 4.77302 2.04492 4.09652C2.04492 3.42003 2.62207 2.87159 3.33691 2.87159C4.04883 2.87159 4.62598 3.42003 4.62598 4.09652C4.62598 4.77024 4.04883 5.32146 3.33691 5.32146ZM12.7822 13.0525H10.5586V9.74514C10.5586 8.95728 10.5439 7.94114 9.40137 7.94114C8.24414 7.94114 8.06836 8.80138 8.06836 9.68946V13.0525H5.84766V6.24851H7.98047V7.17834H8.00977C8.30566 6.64383 9.03223 6.07869 10.1133 6.07869C12.3662 6.07869 12.7822 7.48736 12.7822 9.31919V13.0525Z"
                              fill="#9D13C5"
                            />
                          </svg>{" "}
                          <a style={{ color: "#9D13C5", textDecoration: "none" }} href={`${companyDetails?.company_linkedin}`}> LinkedIN</a>

                        </button>
                        <button className="btn btn-purpal rounded-pill px-4" onClick={() => scrollToConnections()}>
                          See Connections
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-3">
                <div className="card-body">
                  <div className="short-detail-box mb-lg-4 mb-md-4 mb-3">
                    <p>
                      {companyDetails?.company_descriptions}
                    </p>
                  </div>
                  <div className="short-detail-box mb-lg-4 mb-md-4 mb-3">
                    <h3>Company Keywords</h3>

                    <ul className="keywod-list list-unstyled">
                      {companyDetails?.company_keywords?.split(",").map((i, index) => (
                        <li key={index}>{i}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="company-breef-table mb-lg-5 mb-4">
                <div className="table-reaponsive">
                  <table className="table table-dark table-striped">
                    <tbody>
                      <tr>
                        <th>Industry</th>
                        <td>{companyDetails?.company_industry}</td>
                      </tr>
                      <tr>
                        <th>Founding Year</th>
                        <td>{companyDetails?.company_founding_year}</td>
                      </tr>
                      <tr>
                        <th>T-Shirt Size</th>
                        <td>{companyDetails?.T_Shirt_Size}</td>
                      </tr>
                      <tr>
                        <th>Annual Revenue</th>
                        <td>{companyDetails?.annual_revenue}</td>
                      </tr>
                      <tr>
                        <th>Headquaters</th>
                        <td>{companyDetails?.company_headquarters}</td>
                      </tr>
                      <tr>
                        <th>Website</th>
                        <td><a href={`${companyDetails?.website}`}>{companyDetails?.website}</a></td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="our-connnection" id="ourConnections">
                <h3 className="sectionHeading">Our Connections</h3>
                <div className="connection-list list-unstyled row">

                  {prospectDataList?.map((prospect) => (
                    <div key={prospect.id} className="col-lg-4 col-md-6 col-sm-6 mb-4">
                      <div className="card text-center h-100 p-3" style={{ width: "280px" }}>
                        <span className="delete-btn">
                          <img
                            src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e"
                            alt=""
                          />
                        </span>
                        <img
                          src={userIcon}
                          alt="userIcon"
                          className="card-img-top"
                        />
                        <div className="card-body text-center">
                          <div>

                            <h3 className="name">{prospect.first_name} {prospect.last_name}</h3>
                            <h5 className="degination">{prospect.job_title}</h5>
                          </div>
                        </div>
                        <div className="company-logo">
                          {/* <img src={rakLogo} alt="rakLogo" /> */}
                          <img src={companyDetails?.company_logo} alt="rakLogo" width="auto" height="80px" className="" />
                        </div>
                        <div className="card-footer">
                          <div className="btn-box d-flex justify-content-center gap-3">
                            <button className="btn btn-outline-purple rounded-pill" type="button" onClick={() => navigate("/prospect", { state: { companyName: prospect.company_name } })}>
                              View
                            </button>
                            <a className="btn btn-outline-purple rounded-pill" onClick={() => navigate("/prospect", { state: { prospectId: prospect.id, companyName: prospect.company_name } })}>
                              IntelliConnect
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-12 ps-2">
              <ul className="speedometer-box list-unstyled text-white mb-lg-4 mb-3">
                <li>

                  <div className="d-flex" style={{ width: "fit-content" }}>
                    <div className="" style={{ marginTop: "35%", width: "fit-content", height: "fit-content" }}>
                      BuyBeacon
                    </div>
                  </div>
                  <ReactSpeedometer
                    minValue={0}
                    maxValue={10}
                    value={buybeaconRating}
                    segments={5}
                    segmentColors={["red", "orange", "yellow", "lightgreen", "green"]}
                    ringWidth={40}
                    needleHeightRatio={0.7}
                    needleColor="gray"
                    textColor="black"
                  />
                </li>
                <li>
                  <div className="d-flex" style={{ width: "fit-content" }}>
                    <div className="" style={{ marginTop: "35%", width: "fit-content", height: "fit-content" }}>
                      CadenaPay
                    </div>
                  </div>
                  <ReactSpeedometer
                    minValue={0}
                    maxValue={10}
                    value={rating}
                    // currentValueText="Buy Beacon Score"
                    segments={5}
                    // customSegmentStops={[0, 10, 20, 30, 40, 50]}
                    segmentColors={["red", "orange", "yellow", "lightgreen", "green"]}
                    ringWidth={40}
                    needleHeightRatio={0.7}
                    needleColor="gray"
                    textColor="black"
                  />
                </li>
              </ul>
              <div style={styles.outerCard}

              >
                <div style={styles.header}>
                  <h3 style={styles.title}>Cadena Account Manager</h3>
                </div>
                <div style={styles.innerCard}>
                  <div style={styles.profile}>
                    <img
                      src={companyDetails?.agent?.profile_picture}
                      alt="Profile"
                      style={styles.profileImg}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    />
                    <h4 style={styles.profileName}>{companyDetails?.agent?.name}</h4>
                    <p style={styles.profileDesc}>
                      {companyDetails?.agent?.mini_bio}
                    </p>
                  </div>
                  <div style={styles.details}>

                    <img src={BDAY} alt="img" />
                    <span>{companyDetails?.agent?.age}</span>

                    <img style={{ marginLeft: "10px" }} src={gender} alt="img" />
                    <span>{companyDetails?.agent?.gender}</span>

                    <img style={{ marginLeft: "10px" }} src={location} alt="img" />
                    <span>{companyDetails?.agent?.location}</span>
                  </div>
                  {companyDetails?.updates !== "" ? (
                    <div style={styles.updates}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <h5 style={styles.updatesTitle}>Updates</h5>
                        {copyState ? (
                          <span style={{ fontSize: "14px", color: "green" }}>Copied!</span>
                        ) : (
                          <img
                            src={copyIcon}
                            alt="Copy All"
                            style={{ cursor: "pointer", width: "16px", height: "16px" }}
                            onClick={handleCopy}
                          />
                        )}

                      </div>
                      <ul style={{ listStyle: "none", padding: 0 }}>
                        {companyDetails?.updates &&
                          companyDetails?.updates.split(",\r\n").map((update, index) => (
                            <li key={index} style={styles.updateItem}>
                              <div style={{ display: "flex" }}>
                                <div>
                                  <img
                                    className="tick"
                                    src={tick}
                                    alt="tick"
                                    style={{
                                      marginRight: "5px",
                                      color: "#9c27b0",
                                      width: "17px",
                                      height: "13.72px",
                                    }}
                                  />
                                </div>
                                {update}
                               
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}


                  <div style={styles.actions}>
                    <button style={styles.emailButton} onClick={() => {
                      window.location.href = `mailto:${companyDetails.agent.email}`;
                    }} >
                      <img src={email} alt="img" />Email

                    </button>
                    <button style={styles.chatButton}>
                      {/* <i className="fas fa-comments" style={{ marginRight: '5px' }}></i>Chat */}
                      <img src={chat} alt="img" />Chat
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    ...styles.popup,
                    ...(isHovered ? styles.popupVisible : {}),
                  }}
                >

                  <div style={styles.popupHeader}>
                    <img src={companyDetails?.agent?.profile_picture} alt="Profile" style={styles.popupProfileImg} />
                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                      <div style={{ marginLeft: "7px" }}>
                        <h4 style={{ marginTop: "10px", fontSize: "23px", color: "#ffff" }}>{companyDetails?.agent?.name}</h4>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: '#aaa', marginTop: "9px" }}>
                          <img src={BDAY} alt="Age" style={{ width: '16px' }} />
                          <span style={{ color: "white", fontSize: "15px" }}>{companyDetails?.agent?.age}</span>
                          <img src={gender} alt="Gender" style={{ marginLeft: "10px", width: '16px' }} />
                          <span style={{ color: "white", fontSize: "15px" }}>{companyDetails?.agent?.gender}</span>
                          <img src={location} alt="Location" style={{ marginLeft: "10px", width: '16px' }} />
                          <span style={{ color: "white", fontSize: "15px" }}>{companyDetails?.agent?.location}</span>
                        </div>
                        <img
                          src={closeIcon}
                          alt="Close"
                          style={styles.closeIcon}
                          onClick={handleClosePopup}
                        />
                      </div>
                    </div>

                  </div>


                  <div style={{ padding: "20px" }}>
                    <div style={styles.popupRow}>
                      <span style={styles.label}>Citizenship:</span>
                      <span style={styles.value}>{companyDetails?.agent?.citizenship}</span>
                    </div>
                    <div style={styles.popupRow}>
                      <span style={styles.label}>Languages:</span>
                      <span style={styles.value}>{companyDetails?.agent?.language}</span>
                    </div>
                    <div style={styles.popupRow}>
                      <span style={styles.label}>Experience:</span>
                      <span style={styles.value}>{companyDetails?.agent?.experience}</span>
                    </div>
                    <div style={styles.popupRow}>
                      <span style={styles.label}>Deal Size:</span>
                      <span style={styles.value}>{companyDetails?.agent?.deal_size}</span>
                    </div>
                    <div style={styles.popupRow}>
                      <span style={styles.label}>Expertise:</span>
                      <span style={styles.value}>{companyDetails?.agent?.expertise}</span>
                    </div>
                    <div style={styles.popupRow}>
                      <span style={styles.label}>Sales Style:</span>
                      <span style={styles.value}>{companyDetails?.agent?.sales_style}</span>
                    </div>
                    <div style={styles.popupRow}>
                      <span style={styles.label}>Key Contacts:</span>
                      <span style={styles.value}>{companyDetails?.agent?.key_contacts}</span>
                    </div>
                    <div style={styles.popupRowLast}>
                      <span style={styles.label}>Travel Availability:</span>
                      <span style={styles.value}>{companyDetails?.agent?.travel_availability}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="discovery-footer">
        <div className="containerBox">
          <hr />
          <div
            className="d-flex justify-content-between"
            style={{ padding: "15px 0 20px 0" }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="199"
                height="29"
                viewBox="0 0 199 29"
                fill="none"
              >
                <path
                  d="M31.0626 5.71606C29.8417 5.71606 28.6705 5.91439 27.5889 6.2676C29.0761 7.08776 30.3239 8.20741 31.2241 9.54139H39.0103C39.9017 8.21827 41.1396 7.10319 42.6075 6.28932C41.5101 5.91953 40.3196 5.71606 39.0735 5.71606H31.0626Z"
                  fill="#9D13C5"
                />
                <path
                  d="M31.8665 14.4972C31.8613 9.6534 27.392 5.71548 21.9025 5.71548H15.8142C10.3821 5.71548 5.95145 9.57339 5.85254 14.3418L5.85547 14.5024C5.85781 19.3456 10.3271 23.2841 15.8172 23.2841H21.9048C23.1257 23.2841 24.2946 23.0858 25.378 22.732C23.8907 21.9175 22.6399 20.7922 21.7433 19.4582H15.8172C12.6648 19.4582 10.0696 17.4652 9.79508 14.9362H9.7705V14.4966C9.77342 11.7641 12.4868 9.54081 15.8142 9.54081H21.9019C25.0543 9.54081 27.6524 11.5286 27.924 14.0628H27.9486L27.9515 14.4966V14.5024C27.9486 15.4397 27.6249 16.313 27.0706 17.0577C27.9486 17.9207 29.2573 18.4883 30.7334 18.5637C31.4299 17.3904 31.839 16.0667 31.8665 14.6578V14.4972Z"
                  fill="#9D13C5"
                />
                <path
                  d="M61.4767 8.30915C59.5892 6.63739 57.0841 5.71606 54.4175 5.71606H48.3299C42.8404 5.71606 38.3711 9.65398 38.3682 14.4978V14.5304C38.3764 15.9872 38.7878 17.3538 39.5036 18.5592C40.9716 18.4626 42.2639 17.8733 43.1225 17.0051C42.5957 16.2661 42.2855 15.414 42.2855 14.5035C42.2855 14.4978 42.2855 14.4978 42.2855 14.4978C42.2885 11.7653 45.0019 9.54196 48.3293 9.54196H54.4169C57.7473 9.54196 60.4607 11.7658 60.4636 14.4978C60.4636 17.236 57.7502 19.4593 54.4169 19.4593H48.3983C47.5069 20.7825 46.269 21.8975 44.7994 22.7114C45.8968 23.0806 47.0873 23.2847 48.3299 23.2847H54.4175C59.907 23.2847 64.3763 19.3462 64.3816 14.5029C64.3821 12.167 63.3503 9.97004 61.4767 8.30915Z"
                  fill="#9D13C5"
                />
                <path
                  d="M49.0377 14.4972C49.0377 13.0346 48.6262 11.6578 47.9045 10.441C46.4366 10.5376 45.1419 11.1268 44.2827 11.995C44.8095 12.7346 45.1168 13.5867 45.1197 14.4972C45.1197 17.2355 42.4092 19.4588 39.0759 19.4588H31.0627C27.7324 19.4588 25.0189 17.2349 25.0189 14.4972C25.0189 13.5599 25.3455 12.6866 25.8969 11.9413C25.0189 11.084 23.7102 10.5159 22.237 10.441C21.513 11.6521 21.1016 13.0346 21.1016 14.4972C21.1016 19.341 25.5709 23.2847 31.0656 23.2847H39.0765C44.5683 23.2847 49.0377 19.3416 49.0377 14.4972Z"
                  fill="#9D13C5"
                />
                <path
                  d="M92.2538 18.5288L92.295 20.8568L91.1031 20.8768C91.0876 20.8771 90.0491 20.894 88.0181 20.894C86.9891 20.894 85.7055 20.8895 84.171 20.8766C81.6957 20.8555 79.7165 20.3336 78.2885 19.3251C76.8694 18.3228 76.0879 16.8649 76.0879 15.2198C76.0879 13.5759 76.8762 12.1146 78.3076 11.1058C79.7261 10.1057 81.7556 9.57225 84.1768 9.56308C88.732 9.54592 91.0686 9.56336 91.0917 9.56349L92.2838 9.57267L92.265 11.901L91.0729 11.8919C91.05 11.8916 88.7248 11.8743 84.1857 11.8915C80.6615 11.9048 78.4724 13.1802 78.4724 15.2201C78.4724 16.1235 78.8807 16.87 79.6859 17.4387C80.6905 18.1482 82.2486 18.532 84.1915 18.5485C88.7195 18.5871 91.0392 18.5494 91.0618 18.549L92.2538 18.5288ZM104.117 8.57318L113.53 20.783H110.503L107.871 17.3801H100.363L97.7698 20.783H94.7771L104.117 8.57318ZM102.169 15.0286H106.065L104.117 12.4923L102.169 15.0286ZM130.866 11.6947C131.846 12.6657 132.375 13.9097 132.356 15.1974C132.333 16.7914 131.546 18.246 130.141 19.2933C128.677 20.384 126.617 20.9605 124.183 20.9605C119.538 20.9605 117.2 20.8937 117.177 20.893L116.009 20.8589V9.4674H124.183C127.848 9.4674 129.84 10.6786 130.866 11.6947ZM124.184 18.6093C128.131 18.6093 129.924 16.8789 129.949 15.1642C129.959 14.5014 129.683 13.8727 129.151 13.346C128.157 12.3615 126.393 11.8194 124.184 11.8194H118.418V18.5673C119.536 18.5863 121.457 18.6093 124.184 18.6093ZM152.154 11.8189L138.214 11.819V14.0088L147.511 14.0426L147.502 16.3942L138.214 16.3604V18.5422H152.153V20.8937H135.806V9.46765H152.153L152.154 11.8189ZM169.521 9.45661H171.929V20.7249L157.99 13.6154V20.8826H155.582V9.61437L169.522 16.7239L169.521 9.45661ZM183.734 8.57318L193.147 20.783H190.12L187.488 17.3801H179.98L177.387 20.783H174.394L183.734 8.57318ZM181.786 15.0286H185.682L183.734 12.4923L181.786 15.0286Z"
                  fill="#9D13C5"
                />
              </svg>
            </div>

            <div className="d-flex">
              <p>copyright@2023. All rights reserved</p>
            </div>

          </div>
        </div>
      </footer>
    </main>
  );
}

export default CompanyDetail;
