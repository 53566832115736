import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const location = useLocation()

  console.log("location" , location)

  if (isAuthenticated && location.pathname !== '/cards') {
    return <Navigate to="/dashboard" />;
  }
  // if (isAuthenticated) {
  
  //   return <Navigate to="/dashboard" />;
  // }

  return children;
};

export default GuestGuard;
